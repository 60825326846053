
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
import { Modal } from "bootstrap";
import FilterForm  from "./FilterForm.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    FilterForm ,
  },
  data() {
    return {
      detailModal: null,
      Orders: [],
      outForOrders: [],
      rescheduledOrders: [],
      noAnswer: [],
      canceledOrder: [],
      receivedOrder: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchOrders: null,
      OrdersSearchMode: false,
      searchModel: "",
      state: state,
      selectedOrder: {},
      order: {},
      order_status: [],
      selectedStatus: "",
      DeliveryMen: [],
      cities: [],
    };
  },
  mounted() {
    this.detailModal = new Modal(`#banner`);
  },
  methods: {
    handleFormSubmitted(formData) {
      // Handle the form data here
      console.log("Form data received:", formData);
      // Example axios call
      // axios.post('/api/filter', formData).then(response => {
      //   console.log(response.data);
      // });
    },
    showModel(id) {
      this.order.id = null;
      this.order.id = id;
      this.detailModal.show();
      // console.log(data);
    },

    changeStatus() {
      // this.order = data;
      this.order.status = null;
      this.order.status = this.selectedStatus;
      // this.detailModal.show();
      console.log(this.order);
    },
    resetOrder() {
      this.order = {};
    },
    openModal(order) {
      this.$refs.orderModal.showOrderModal(order);
    },
    addOrders() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addOrders",
        inputs: [
          {
            model: "delivery_man_id",
            type: "text",
            label: "orders.delivery_man_id",
          },
          { model: "photo", type: "text", label: "orders.photo" },
          { model: "old_date", type: "text", label: "orders.old_date" },
          {
            model: "manually_assigned",
            type: "text",
            label: "orders.manually_assigned",
          },
          { model: "package_date", type: "text", label: "orders.package_date" },
          { model: "longitude", type: "text", label: "orders.longitude" },
          { model: "created_at", type: "text", label: "orders.created_at" },
          { model: "type", type: "text", label: "orders.type" },
          { model: "source", type: "text", label: "orders.source" },
          { model: "note", type: "text", label: "orders.note" },
          { model: "sacks", type: "text", label: "orders.sacks" },
          { model: "updated_at", type: "text", label: "orders.updated_at" },
          {
            model: "logistics_feedback",
            type: "text",
            label: "orders.logistics_feedback",
          },
          { model: "door_image", type: "text", label: "orders.door_image" },
          { model: "district_id", type: "text", label: "orders.district_id" },
          {
            model: "airtablestatus",
            type: "text",
            label: "orders.airtablestatus",
          },
          {
            model: "is_rescheduled",
            type: "text",
            label: "orders.is_rescheduled",
          },
          {
            model: "donation_date",
            type: "text",
            label: "orders.donation_date",
          },
          { model: "city_id", type: "text", label: "orders.city_id" },
          { model: "status_cc", type: "text", label: "orders.status_cc" },
          { model: "status", type: "text", label: "orders.status" },
          { model: "weight", type: "text", label: "orders.weight" },
          {
            model: "reschedule_reason",
            type: "text",
            label: "orders.reschedule_reason",
          },
          {
            model: "cancel_reason",
            type: "text",
            label: "orders.cancel_reason",
          },
          { model: "company", type: "text", label: "orders.company" },
          { model: "latitude", type: "text", label: "orders.latitude" },
          {
            model: "delivery_man_status",
            type: "text",
            label: "orders.delivery_man_status",
          },
          {
            model: "rescheduled_date",
            type: "text",
            label: "orders.rescheduled_date",
          },
          { model: "customer_id", type: "text", label: "orders.customer_id" },
          { model: "principal_id", type: "text", label: "orders.principal_id" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("orders", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editOrders(app) {
      var data = {
        title: "popups.orders",
        inputs: [
          {
            model: "delivery_man_id",
            type: "text",
            label: "orders.delivery_man_id",
            value: app.delivery_man_id,
          },
          {
            model: "photo",
            type: "text",
            label: "orders.photo",
            value: app.photo,
          },
          {
            model: "old_date",
            type: "text",
            label: "orders.old_date",
            value: app.old_date,
          },
          {
            model: "manually_assigned",
            type: "text",
            label: "orders.manually_assigned",
            value: app.manually_assigned,
          },
          {
            model: "package_date",
            type: "text",
            label: "orders.package_date",
            value: app.package_date,
          },
          {
            model: "longitude",
            type: "text",
            label: "orders.longitude",
            value: app.longitude,
          },
          {
            model: "created_at",
            type: "text",
            label: "orders.created_at",
            value: app.created_at,
          },
          {
            model: "type",
            type: "text",
            label: "orders.type",
            value: app.type,
          },
          {
            model: "source",
            type: "text",
            label: "orders.source",
            value: app.source,
          },
          {
            model: "note",
            type: "text",
            label: "orders.note",
            value: app.note,
          },
          {
            model: "sacks",
            type: "text",
            label: "orders.sacks",
            value: app.sacks,
          },
          {
            model: "updated_at",
            type: "text",
            label: "orders.updated_at",
            value: app.updated_at,
          },
          {
            model: "logistics_feedback",
            type: "text",
            label: "orders.logistics_feedback",
            value: app.logistics_feedback,
          },
          {
            model: "door_image",
            type: "text",
            label: "orders.door_image",
            value: app.door_image,
          },
          {
            model: "district_id",
            type: "text",
            label: "orders.district_id",
            value: app.district_id,
          },
          {
            model: "airtablestatus",
            type: "text",
            label: "orders.airtablestatus",
            value: app.airtablestatus,
          },
          {
            model: "is_rescheduled",
            type: "text",
            label: "orders.is_rescheduled",
            value: app.is_rescheduled,
          },
          {
            model: "donation_date",
            type: "text",
            label: "orders.donation_date",
            value: app.donation_date,
          },
          {
            model: "city_id",
            type: "text",
            label: "orders.city_id",
            value: app.city_id,
          },
          {
            model: "status_cc",
            type: "text",
            label: "orders.status_cc",
            value: app.status_cc,
          },
          {
            model: "status",
            type: "text",
            label: "orders.status",
            value: app.status,
          },
          {
            model: "weight",
            type: "text",
            label: "orders.weight",
            value: app.weight,
          },
          {
            model: "reschedule_reason",
            type: "text",
            label: "orders.reschedule_reason",
            value: app.reschedule_reason,
          },
          {
            model: "cancel_reason",
            type: "text",
            label: "orders.cancel_reason",
            value: app.cancel_reason,
          },
          {
            model: "company",
            type: "text",
            label: "orders.company",
            value: app.company,
          },
          {
            model: "latitude",
            type: "text",
            label: "orders.latitude",
            value: app.latitude,
          },
          {
            model: "delivery_man_status",
            type: "text",
            label: "orders.delivery_man_status",
            value: app.delivery_man_status,
          },
          {
            model: "rescheduled_date",
            type: "text",
            label: "orders.rescheduled_date",
            value: app.rescheduled_date,
          },
          {
            model: "customer_id",
            type: "text",
            label: "orders.customer_id",
            value: app.customer_id,
          },
          {
            model: "principal_id",
            type: "text",
            label: "orders.principal_id",
            value: app.principal_id,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("orders", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    getOrders() {
      this.http.get("orders").then((res) => {
        this.Orders = res.data;
      });
    },

    OrdersStatus() {
      this.http.get("order-status").then((res) => {
        this.order_status = res.data;
      });
    },

    deleteOrders(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("orders", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    getAreas() {
      this.http.get("areas").then((res) => {
        this.cities = res.data;
      });
    },
    getDeliveryMen() {
      this.http.get("delivery-men").then((res) => {
        this.DeliveryMen = res.data;
      });
    },

    submitOrder() {
      // Handle form submission
      // You can use Axios or any other method to send the data to your server
      console.log("Order Data:", this.order);

      if (this.order.status == 3) {
        this.order.is_rescheduled = true;
      }
      this.http.put("orders", this.order.id, this.order).then((res) => {
        if (res.status) {
          this.get(1);
        }
      });

      // Example Axios request
      // axios.post('/api/orders', this.order)
      //   .then(response => {
      //     console.log('Order submitted successfully', response);
      //   })
      //   .catch(error => {
      //     console.error('Error submitting order', error);
      //   });

      // Optionally hide the modal after submission
      this.$refs.banner.hide();
    },

    get(page) {
      console.log(page);
      this.http
        .post("orders/paginate", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Orders = res.data;
          this.outForOrders = this.Orders.filter((item) => {
            if (item.status == 2) return item;
          });
          this.rescheduledOrders = this.Orders.filter((item) => {
            if (item.status == 3) return item;
          });
          this.noAnswer = this.Orders.filter((item) => {
            if (item.status == 4) return item;
          });
          this.canceledOrder = this.Orders.filter((item) => {
            if (item.status == 5) return item;
          });
          this.receivedOrder = this.Orders.filter((item) => {
            if (item.status == 6) return item;
          });
          console.log(424, this.receivedOrder);
        });
    },
  },
  created() {
    this.get(1);
    this.getAreas(1);
    this.getDeliveryMen(1);
    this.OrdersStatus();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.orders.text')"
    />

    <b-tabs
      content-class="p-4"
      class="pt-2"
      nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom"
    >
      <b-tab :title="$t('status.new_orders')" active>
        <div class="row">
          <div class="col">
            <FilterForm :cities="cities" :DeliveryMen="DeliveryMen" @form-submitted="handleFormSubmitted" />
          </div>
        </div>

        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("orders.id") }}</th>
              <th scope="col">{{ $t("orders.customer_id") }}</th>
              <th scope="col">{{ $t("orders.phone") }}</th>
              <th scope="col">{{ $t("orders.source") }}</th>
              <th scope="col">{{ $t("orders.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(app, index) in Orders" :key="app" class="text-center">
              <td>{{ index + 1 }}</td>
              <td>Customer Name</td>
              <td>phone</td>
              <td>{{ app.source }}</td>
              <td colspan="2">
                <a
                  class="btn btn-warning mx-1"
                  @click="showModel(app.id)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.change_status") }}</a
                >
                <a
                  class="btn btn-primary mx-1"
                  @click="editOrders(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteOrders(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->
        <div v-if="tot_pages > 0">
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <!-- Previous Page Link -->
            <li class="page-item" :class="{ disabled: page === 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>

            <!-- Page Number Links -->
            <li
              class="page-item"
              :class="{ active: p === page }"
              v-for="p in totalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <!-- Next Page Link -->
            <li class="page-item" :class="{ disabled: page === totalPages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab :title="$t('status.out_for_delivery')">
        <div class="row">
          <div class="col">
            <FilterForm :cities="cities" :DeliveryMen="DeliveryMen" @form-submitted="handleFormSubmitted" />
          </div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("orders.id") }}</th>
              <th scope="col">{{ $t("orders.customer_id") }}</th>
              <th scope="col">{{ $t("orders.phone") }}</th>
              <th scope="col">{{ $t("orders.source") }}</th>
              <th scope="col">{{ $t("orders.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(app, index) in outForOrders"
              :key="app"
              class="text-center"
            >
              <td>{{ index + 1 }}</td>
              <td>Customer Name</td>
              <td>phone</td>
              <td>{{ app.source }}</td>

              <td colspan="2">
                <a
                  class="btn btn-warning mx-1"
                  @click="showModel(app.id)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.change_status") }}</a
                >
                <a
                  class="btn btn-primary mx-1"
                  @click="editOrders(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteOrders(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->
        <div v-if="tot_pages > 0">
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <!-- Previous Page Link -->
            <li class="page-item" :class="{ disabled: page === 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>

            <!-- Page Number Links -->
            <li
              class="page-item"
              :class="{ active: p === page }"
              v-for="p in totalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <!-- Next Page Link -->
            <li class="page-item" :class="{ disabled: page === totalPages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab :title="$t('status.rescheduled')">
        <div class="row">
          <div class="col">
            <FilterForm :cities="cities" :DeliveryMen="DeliveryMen" @form-submitted="handleFormSubmitted" />
          </div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("orders.id") }}</th>
              <th scope="col">{{ $t("orders.customer_id") }}</th>
              <th scope="col">{{ $t("orders.phone") }}</th>
              <th scope="col">{{ $t("orders.source") }}</th>
              <th scope="col">{{ $t("orders.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(app, index) in rescheduledOrders"
              :key="app"
              class="text-center"
            >
              <td>{{ index + 1 }}</td>
              <td>Customer Name</td>
              <td>phone</td>
              <td>{{ app.source }}</td>
              <td colspan="2">
                <a
                  class="btn btn-warning mx-1"
                  @click="showModel(app.id)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.change_status") }}</a
                >
                <a
                  class="btn btn-primary mx-1"
                  @click="editOrders(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteOrders(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->
        <div v-if="tot_pages > 0">
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <!-- Previous Page Link -->
            <li class="page-item" :class="{ disabled: page === 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>

            <!-- Page Number Links -->
            <li
              class="page-item"
              :class="{ active: p === page }"
              v-for="p in totalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <!-- Next Page Link -->
            <li class="page-item" :class="{ disabled: page === totalPages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab :title="$t('status.no_answer')">
        <div class="row">
          <div class="col">
            <FilterForm :cities="cities" :DeliveryMen="DeliveryMen" @form-submitted="handleFormSubmitted" />
          </div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("orders.id") }}</th>
              <th scope="col">{{ $t("orders.customer_id") }}</th>
              <th scope="col">{{ $t("orders.phone") }}</th>
              <th scope="col">{{ $t("orders.source") }}</th>
              <th scope="col">{{ $t("orders.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(app, index) in noAnswer" :key="app" class="text-center">
              <td>{{ index + 1 }}</td>
              <td>Customer Name</td>
              <td>phone</td>
              <td>{{ app.source }}</td>
              <td colspan="2">
                <a
                  class="btn btn-warning mx-1"
                  @click="showModel(app.id)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.change_status") }}</a
                >
                <a
                  class="btn btn-primary mx-1"
                  @click="editOrders(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteOrders(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->
        <div v-if="tot_pages > 0">
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <!-- Previous Page Link -->
            <li class="page-item" :class="{ disabled: page === 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>

            <!-- Page Number Links -->
            <li
              class="page-item"
              :class="{ active: p === page }"
              v-for="p in totalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <!-- Next Page Link -->
            <li class="page-item" :class="{ disabled: page === totalPages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab :title="$t('status.cancelled')">
        <div class="row">
          <div class="col">
            <FilterForm :cities="cities" :DeliveryMen="DeliveryMen" @form-submitted="handleFormSubmitted" />
          </div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("orders.id") }}</th>
              <th scope="col">{{ $t("orders.customer_id") }}</th>
              <th scope="col">{{ $t("orders.phone") }}</th>
              <th scope="col">{{ $t("orders.source") }}</th>
              <th scope="col">{{ $t("orders.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(app, index) in canceledOrder"
              :key="app"
              class="text-center"
            >
              <td>{{ index + 1 }}</td>
              <td>Customer Name</td>
              <td>phone</td>
              <td>{{ app.source }}</td>
              <td colspan="2">
                <a
                  class="btn btn-warning mx-1"
                  @click="showModel(app.id)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.change_status") }}</a
                >
                <a
                  class="btn btn-primary mx-1"
                  @click="editOrders(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteOrders(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->
        <div v-if="tot_pages > 0">
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <!-- Previous Page Link -->
            <li class="page-item" :class="{ disabled: page === 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>

            <!-- Page Number Links -->
            <li
              class="page-item"
              :class="{ active: p === page }"
              v-for="p in totalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <!-- Next Page Link -->
            <li class="page-item" :class="{ disabled: page === totalPages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
      <b-tab :title="$t('status.received')">
        <div class="row">
          <div class="col">
            <FilterForm :cities="cities" :DeliveryMen="DeliveryMen" @form-submitted="handleFormSubmitted" />
          </div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("orders.id") }}</th>
              <th scope="col">{{ $t("orders.customer_id") }}</th>
              <th scope="col">{{ $t("orders.phone") }}</th>
              <th scope="col">{{ $t("orders.source") }}</th>
              <th scope="col">{{ $t("orders.operations") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(app, index) in receivedOrder"
              :key="app"
              class="text-center"
            >
              <td>{{ index + 1 }}</td>
              <td>Customer Name</td>
              <td>phone</td>
              <td>{{ app.source }}</td>
              <td colspan="2">
                <a
                  class="btn btn-success mx-1"
                  @click="editOrders(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.change_status") }}</a
                >
                <a
                  class="btn btn-primary mx-1"
                  @click="editOrders(app)"
                  href="javascript: void(0);"
                  role="button"
                  >{{ $t("popups.edit") }}</a
                >
                <a
                  class="btn btn-danger"
                  href="javascript: void(0);"
                  role="button"
                  @click="deleteOrders(app)"
                  >{{ $t("popups.delete") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
        <!-- end  table -->
        <!-- Button trigger modal -->
        <div v-if="tot_pages > 0">
          <ul class="paginate paginate-rounded justify-content-center mb-2">
            <!-- Previous Page Link -->
            <li class="page-item" :class="{ disabled: page === 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>

            <!-- Page Number Links -->
            <li
              class="page-item"
              :class="{ active: p === page }"
              v-for="p in totalPages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <!-- Next Page Link -->
            <li class="page-item" :class="{ disabled: page === totalPages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </b-tab>
    </b-tabs>
    <!--end-->

    <b-modal id="banner" title="Order Details" @hide="resetOrder">
      <form @submit.prevent="submitOrder">
        <div class="row">
          <div class="col">
            <select
              v-model="selectedStatus"
              class="form-control"
              @change="changeStatus"
            >
              <option
                v-for="status in order_status"
                :key="status.id"
                :value="status.id"
              >
                {{ status.name }}
              </option>
            </select>
          </div>
        </div>

        <div v-if="order.status == 4">
          <div class="row">
            <label for="">Select Answer</label>
            <div class="col">
              <select v-model="order.answer" class="form-control" id="reason">
                <option value="1">No Answer 1</option>
                <option value="2">No Answer 2</option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="order.status == 5">
          <div class="row mt-2">
            <div class="col">
              <label for="rescheduleDate">Cancel reason </label>
              <textarea
                class="form-control"
                v-model="order.cancel_reason"
              ></textarea>
            </div>
          </div>
        </div>

        <div v-else-if="order.status == 3">
          <div class="row">
            <div class="col">
              <label for="rescheduleDate">Reschedule Date</label>
              <input
                type="date"
                v-model="order.rescheduled_date"
                id="rescheduleDate"
                class="form-control"
              />
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <label for="Status CC">Status CC</label>

              <select
                v-model="order.status_cc"
                class="form-control"
                id="reason"
              >
                <option value="إعادة جدولة 1">إعادة جدولة 1</option>
                <option value="إعادة جدولة 2">إعادة جدولة 2</option>
              </select>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.paginate {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.page-item {
  display: inline-block;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>




