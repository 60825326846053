<template>
  <div class="container mt-3">
    <div class="card">
      <div class="card-header bg-dark">
        <div class="card-title">
          <h4 style="color: #ffffff !important">
            {{ $t("filter.filter_form_title") }}
          </h4>
        </div>
      </div>
      <form @submit.prevent="submitForm">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="orderId">{{ $t("filter.filter.order_id") }}</label>
              <input
                type="text"
                class="form-control"
                id="orderId"
                v-model="formData.orderId"
                :placeholder="$t('filter.filter.order_id_placeholder')"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="donatorId">{{ $t("filter.filter.donator_id") }}</label>
              <input
                type="text"
                class="form-control"
                id="donatorId"
                v-model="formData.donatorId"
                :placeholder="$t('filter.filter.donator_id_placeholder')"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="donatorName">{{ $t("filter.filter.donator_name") }}</label>
              <input
                type="text"
                class="form-control"
                id="donatorName"
                v-model="formData.donatorName"
                :placeholder="$t('filter.filter.donator_name_placeholder')"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="phone">{{ $t("filter.filter.phone") }}</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                v-model="formData.phone"
                :placeholder="$t('filter.filter.phone_placeholder')"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="city">{{ $t("filter.filter.city") }}</label>
              <select class="form-control" id="city" v-model="formData.city">
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label for="delivery">{{ $t("filter.filter.delivery") }}</label>
              <select class="form-control" id="delivery" v-model="formData.delivery">
                <option v-for="man in DeliveryMen" :key="man.id" :value="man.id">
                  {{ man.full_name }}
                </option>
              </select>
            </div>
            <!-- Uncomment and add options if needed -->
            <!-- <div class="form-group col-md-3">
              <label for="orderType">{{ $t("filter.filter.order_type") }}</label>
              <select class="form-control" id="orderType" v-model="formData.orderType">
                <option value="">
                  {{ $t("filter.filter.select_order_type") }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label for="source">{{ $t("filter.filter.source") }}</label>
              <select class="form-control" id="source" v-model="formData.source">
                <option value="">
                  {{ $t("filter.filter.select_source") }}
                </option>
              </select>
            </div> -->
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="company">{{ $t("filter.filter.company") }}</label>
              <select class="form-control" id="company" v-model="formData.company">
                <option value="">
                  {{ $t("filter.filter.select_company") }}
                </option>
                <!-- Add options here -->
              </select>
            </div>
            <div class="form-group col-md-3">
              <label for="callCenterStatus">{{ $t("filter.filter.call_center_status") }}</label>
              <select class="form-control" id="callCenterStatus" v-model="formData.callCenterStatus">
                <option value="">
                  {{ $t("filter.filter.select_call_center_status") }}
                </option>
                <!-- Add options here -->
              </select>
            </div>
            <div class="form-group col-md-3">
              <label for="principal">{{ $t("filter.filter.principal") }}</label>
              <select class="form-control" id="principal" v-model="formData.principal">
                <option value="">
                  {{ $t("filter.filter.select_principal") }}
                </option>
                <!-- Add options here -->
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="deliveryStart">{{ $t("filter.filter.by_delivery_date") }}</label>
              <input
                type="date"
                class="form-control"
                id="deliveryStart"
                v-model="formData.deliveryStart"
                :placeholder="$t('filter.filter.date_placeholder')"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="deliveryEnd">{{ $t("filter.filter.end") }}</label>
              <input
                type="date"
                class="form-control"
                id="deliveryEnd"
                v-model="formData.deliveryEnd"
                :placeholder="$t('filter.filter.date_placeholder')"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="requestStart">{{ $t("filter.filter.by_request_date") }}</label>
              <input
                type="date"
                class="form-control"
                id="requestStart"
                v-model="formData.requestStart"
                :placeholder="$t('filter.filter.date_placeholder')"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="requestEnd">{{ $t("filter.filter.end") }}</label>
              <input
                type="date"
                class="form-control"
                id="requestEnd"
                v-model="formData.requestEnd"
                :placeholder="$t('filter.filter.date_placeholder')"
              />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12 text-right">
            <button type="submit" class="btn btn-primary mx-1">
              {{ $t("filter.filter.submit") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary mx-1"
              @click="resetForm"
            >
              {{ $t("filter.filter.reset") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cities: {
      type: Array,
      default: () => []
    },
    DeliveryMen: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formData: {
        orderId: "",
        donatorId: "",
        donatorName: "",
        phone: "",
        city: "",
        delivery: "",
        orderType: "",
        source: "",
        company: "",
        callCenterStatus: "",
        principal: "",
        deliveryStart: "",
        deliveryEnd: "",
        requestStart: "",
        requestEnd: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.$emit("form-submitted", this.formData);
    },
    resetForm() {
      this.formData = {
        orderId: "",
        donatorId: "",
        donatorName: "",
        phone: "",
        city: "",
        delivery: "",
        orderType: "",
        source: "",
        company: "",
        callCenterStatus: "",
        principal: "",
        deliveryStart: "",
        deliveryEnd: "",
        requestStart: "",
        requestEnd: "",
      };
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
}
.form-row {
  margin-bottom: 1rem;
}
.text-right {
  text-align: right;
}
</style>
